export enum WebSocketTopic {
    SyncStatus = 0,
    PublicNodeStatus = 1,
    NodeInfoExtended = 2,
    GossipMetrics = 3,
    PeerMetrics = 4,
    NetworkMetrics = 5,
    DatabaseSizeMetric = 6,
    VisualizerVertex = 7,
    VisualizerBlockStateInfo = 8,
    VisualizerTipInfo = 9,
}
